import ReactDOM from 'react-dom/client'
import { App } from './components/App/App'
import { mergeStyles } from '@fluentui/react'
import { initializeIcons, registerIcons } from '@fluentui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import store from './redux/store'
import { Provider } from 'react-redux'
import { AppInitializer } from './components/App/AppInitializer'
import './localization/i18n'
import {
  AboutView,
  ChannelFeedView,
  ChannelSearchView,
  CreateChannelView,
  CreatePostView,
  ManageChannelElementsView,
  ManageChannelFieldsView,
  ManageChannelFiltersView,
  ManageChannelView,
} from './components/View'
import { SettingsView } from './components/View/SettingsView/SettingsView'
import { EditPostView } from './components/View/EditPostView/EditPostView'
import { SupportView } from './components/View/SupportView/SupportView'
import { NotFoundView } from './components/View/NotFoundView/NotFoundView'
import { MsalInitializer } from './auth/MsalInitializer'
import { UserView } from './components/View/UserView'

registerIcons({
  icons: {
    custom_notidar: (
      <svg viewBox='10 10 100 100' xmlns='http://www.w3.org/2000/svg'>
        <title>Notidar</title>
        <ellipse stroke='#0078d4' strokeWidth='10' fill='none' cx='60' cy='60' id='svg_1' rx='45' ry='45' />
        <ellipse stroke='#0078d4' fill='none' strokeWidth='10' cx='60' cy='60' id='svg_3' rx='25' ry='25' />
        <ellipse fill='#0078d4' strokeWidth='0' cx='60' cy='60' id='svg_6' rx='10' ry='10' stroke='#000' />
      </svg>
    ),
    custom_rss: (
      <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 12 12'>
        {/* <rect x='0' y='0' width='16' height='16' fill='none' stroke='none' /> */}
        <path
          fill='currentColor'
          d='M2 4.5A2.5 2.5 0 0 1 4.5 2h7A2.5 2.5 0 0 1 14 4.5v7a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 2 11.5zM4.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3zm1 8.25a.75.75 0 1 0 0-1.5a.75.75 0 0 0 0 1.5M9 10.5C9 8.57 7.43 7 5.5 7a.5.5 0 0 0 0 1C6.879 8 8 9.121 8 10.5a.5.5 0 0 0 1 0m3 0C12 6.916 9.084 4 5.5 4a.5.5 0 0 0 0 1C8.532 5 11 7.468 11 10.5a.5.5 0 0 0 1 0'
        />
      </svg>
    ),
  },
})

// init fluent icons
initializeIcons()

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100%',
    width: '100vw',
    overflow: 'hidden',
  },
})

console.debug(process.env)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  //<React.StrictMode>
  // <ThemeProvider style={{ height: '100%' }} theme={lightTheme}>
  <BrowserRouter>
    <MsalInitializer>
      <Routes>
        <Route path='/auth' />
        <Route
          path='/'
          element={
            <Provider store={store}>
              <AppInitializer>
                <App />
              </AppInitializer>
            </Provider>
          }
        >
          <Route path='/manage/channel/:channelIdentifier/fields' element={<ManageChannelFieldsView />} />
          <Route path='/manage/channel/:channelIdentifier/filters' element={<ManageChannelFiltersView />} />
          <Route path='/manage/channel/:channelIdentifier/sections' element={<ManageChannelElementsView />} />
          <Route path='/manage/channel/:channelIdentifier/post/:postId' element={<EditPostView />} />
          <Route path='/manage/channel/:channelIdentifier/post' element={<CreatePostView />} />
          <Route path='/manage/channel/:channelIdentifier' element={<ManageChannelView />} />
          <Route path='/manage/channel' element={<CreateChannelView />} />
          <Route path='/channel/:channelIdentifier/subscription/:subscriptionId' element={<ChannelFeedView />} />
          <Route path='/channel/:channelIdentifier' element={<ChannelFeedView />} />
          <Route path='/about' element={<AboutView />} />
          <Route path='/support' element={<SupportView />} />
          <Route path='/channel' element={<ChannelSearchView />} />
          <Route path='/settings' element={<SettingsView />} />
          <Route path='/user/me' element={<UserView />} />
          <Route path='/' element={<ChannelSearchView />} />
          <Route path='*' element={<NotFoundView />} />
        </Route>
      </Routes>
    </MsalInitializer>
  </BrowserRouter>,
  //</ThemeProvider>
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
