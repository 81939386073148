import React, { useState } from 'react'
import { Nav, INavLink, INavLinkGroup, INavStyles, ITheme, useTheme } from '@fluentui/react'
import { useAppSelector } from '../../redux/hooks'
import { selectSubscriptionsState } from '../../redux/features/subscriptionsSlice'
import { selectUserChannelsState } from '../../redux/features/userChannelsSlice'
import { useMenuOnClickNavigate, useOnSignIn } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { selectUserState } from '../../redux/features/userSlice'
import { PublisherAction, canPublish } from '../../auth/utils'
import { useAccount } from '@azure/msal-react'

const getNavStyles = (theme: ITheme): Partial<INavStyles> => {
  return {
    root: {
      boxSizing: 'border-box',
      overflowY: 'auto',
    },
    groupContent: {
      animation: 'none',
      margin: 0,
      borderWidth: '0px 0px 1px 0px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralTertiaryAlt,
    },
    chevronButton: {
      margin: 0,
      borderColor: theme.palette.neutralTertiaryAlt,
    },
    link: {
      color: theme.palette.black,
    },
  }
}

export const Navigation = () => {
  const { t } = useTranslation()
  const { user } = useAppSelector(selectUserState)
  const { subscriptions } = useAppSelector(selectSubscriptionsState)
  const { channels: userChannels } = useAppSelector(selectUserChannelsState)
  const theme = useTheme()
  const navStyles = getNavStyles(theme)
  const menuOnClickNavigate = useMenuOnClickNavigate()
  const account = useAccount()
  const onSignIn = useOnSignIn()

  const [subscriptionsExpanded, setSubscriptionsExpanded] = useState(true)
  const [manageExpanded, setManageExpanded] = useState(false)

  const onManageMenuClick = () => {
    return (ev?: React.MouseEvent<HTMLElement>) => {
      setManageExpanded(!manageExpanded)
      ev?.preventDefault()
    }
  }

  const onSubscriptionsMenuClick = () => {
    return (ev?: React.MouseEvent<HTMLElement>) => {
      setSubscriptionsExpanded(!subscriptionsExpanded)
      ev?.preventDefault()
    }
  }

  let links: INavLink[] = []
  const navLinkGroups: INavLinkGroup[] = [{ links: links }]

  links.push({
    name: t('menu.search'),
    url: '/channel',
    key: 'search',
    onClick: menuOnClickNavigate('/channel'),
  })

  if(!account) {
    links.push({
      name: t('menu.create_account'),
      url: '/create_account',
      key: 'create_account',
      onClick: () => onSignIn(),
    })
  }

  if (subscriptions !== undefined) {
    const subsLinks =
      subscriptions.length > 0
        ? subscriptions.map(x => ({
            url: `/channel/${x.displayName}/subscription/${x.subscriptionId}`,
            onClick: menuOnClickNavigate(`/channel/${x.displayName}/subscription/${x.subscriptionId}`),
            key: `/channel/${x.displayName}/subscription/${x.subscriptionId}`,
            name: x.title ?? '',
          }))
        : [
            {
              disabled: true,
              url: '',
              key: 'no_subscriptions',
              name: t('menu.no_subscriptions'),
            },
          ]

    links.push({
      name: t('menu.subscriptions'),
      url: '',
      onClick: onSubscriptionsMenuClick(),
      isExpanded: subscriptionsExpanded,
      links: subsLinks,
    })
  }

  if (userChannels && userChannels.length > 0) {
    const subsLinks = userChannels.map(x => ({
      name: x.title ?? '',
      url: `/manage/channel/${x.name}`,
      key: `/manage/channel/${x.name}`,
      onClick: menuOnClickNavigate(`/manage/channel/${x.name}`),
    }))

    links.push({
      name: t('menu.manage'),
      url: '',
      onClick: onManageMenuClick(),
      isExpanded: manageExpanded,
      links: subsLinks,
    })
  }

  if (canPublish(user, PublisherAction.ManageChannels)) {
    links.push({
      name: t('menu.create_channel'),
      url: '/manage/channel',
      key: '/manage/channel',
      onClick: menuOnClickNavigate('/manage/channel'),
    })
  }

  links.push({
    name: t('menu.support'),
    url: '/support',
    key: 'support',
    onClick: menuOnClickNavigate('/support'),
  })

  links.push({
    name: t('menu.about'),
    key: 'about',
    url: '/about',
    onClick: menuOnClickNavigate('/about'),
  })

  return <Nav groups={navLinkGroups} styles={navStyles} />
}
